import { getCurrentApp } from 'common/constants/appNames';
import React, { useEffect } from 'react';
const DatadogRumProvider = ({ children }) => {
    useEffect(() => {
        const loadDatadogRum = () => {
            try {
                const script = document.createElement('script');
                script.src = 'https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js';
                script.async = false;
                script.onload = () => {
                    if (window.DD_RUM) {
                        window.DD_RUM.init({
                            clientToken: 'pubdeddc0d40240f50bb5d78e4575962f52',
                            applicationId: 'edb3ffa5-1e4d-4e36-8cac-c47c5ca55a12',
                            site: 'datadoghq.com',
                            service: 'mfe',
                            env: localStorage.getItem('environment') ?? 'development',
                            sessionSampleRate: 100,
                            sessionReplaySampleRate: 100,
                            trackUserInteractions: true,
                            trackResources: true,
                            trackLongTasks: true,
                            defaultPrivacyLevel: 'allow',
                            version: VERSION,
                            beforeSend: (event) => {
                                let currentApp = 'mfe';
                                try {
                                    currentApp = getCurrentApp();
                                }
                                catch (error) {
                                    console.log('error in getting current app, setting to mfe', error);
                                }
                                event.service = currentApp;
                            },
                        });
                    }
                    else {
                        console.error('Datadog RUM not found on window object');
                    }
                };
                script.onerror = () => {
                    console.error('Error loading Datadog RUM script');
                };
                document.head.appendChild(script);
            }
            catch (error) {
                console.error('Error initializing Datadog RUM', error);
            }
        };
        loadDatadogRum();
    }, []);
    return <>{children}</>;
};
export default DatadogRumProvider;
