import { assetUIRoutes } from "./assetUIRoutes";
import PermissionService from '@services/permission.service';

export const appNames = {
    ControlCenter: 'ControlCenter',
    CriticalComms: 'CriticalComms',
    RiskIntelligence: 'RiskIntelligence',
    AssetUI: 'AssetUI',
    HistoricalReporting: 'HistoricalReporting',
};



export const getCurrentApp = () => {
    const { canAccessAssetUI, canViewHistoricalReporting } = new PermissionService().permissions;
    const currentLocation = window.location?.pathname.toLowerCase() ?? '';

    if (currentLocation.includes('controlcenter') || currentLocation == '/') {
        return appNames.ControlCenter;
    } else if (currentLocation.includes('riskintelligence')) {
        return appNames.RiskIntelligence;
    } else if (canViewHistoricalReporting && currentLocation.includes('eventreporting')) {
        return appNames.HistoricalReporting;
    } else if (!canViewHistoricalReporting && currentLocation.includes('eventreporting')) {
        return appNames.CriticalComms;
    } else if (canAccessAssetUI && assetUIRoutes.some((assetRoute) => currentLocation.includes(assetRoute))) {
        return appNames.AssetUI;
    } else if (!canAccessAssetUI && assetUIRoutes.some((assetRoute) => currentLocation.includes(assetRoute))) {
        return appNames.CriticalComms;
    } else if (currentLocation.includes('/reports/dashboards')) {
        return appNames.HistoricalReporting;
    } else if (currentLocation.includes('incident_management')) {
        return "IncidentManagement";
    } else if (currentLocation.includes('critical') || currentLocation.includes('alerts') || currentLocation.includes('schedules') || currentLocation.includes('reports') || currentLocation.includes('settings') || currentLocation.includes('subscriptions') || currentLocation.includes('configure') || currentLocation.includes('support-training') || currentLocation.includes('contacts')) {
        return appNames.CriticalComms;
    } else {
        return 'mfe';
    }
};



