import BetaToastrContent from '@components/BetaToastr/BetaToastr';
import SessionTimeoutModal from '@components/SessionTimeoutModal/SessionTimeoutModal';
import { useI18n } from 'modules/Common/components/I18n/context';
import { i18nSetCookie } from 'modules/Common/components/I18n/utility';
import { DomUtils, LoadingOverlay, ToastrProvider, ToastrProviderComponent } from '@onsolve/onsolve-ui-components';
import queryString from 'query-string';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { isBeta, isDevelopment } from 'utils/envUtilities';
import { appNames } from './common/constants/appNames';
import { controlCenterRoutes } from './common/constants/controlCenterRoutes';
import { assetUIRoutes } from 'common/constants/assetUIRoutes';
import cookieKeys from './common/constants/cookieKeys';
import identityServerErrorCodes from './common/constants/identityServerErrorCodes';
import MFERoutesEnum from './common/constants/mfeRoutesEnum';
import isMobile from './common/utility/isMobile';
import LocalStorageUtility from './common/utility/localStorageUtility';
import { updateHistoryWithFilterId, updatedPush } from './common/utility/updatedPush';
import { useRIAccountContext } from './context/riAccountContext';
import MicroFrontend from './MicroFrontend/MicroFrontend';
import NavBar from './Navigation/SideBar/NavBar';
import AuthService from './services/auth.service';
import configurationService from './services/config.service';
import CookieService from './services/cookie.service';
import namespaceService from './services/namespace.service';
import { useAccountContext } from '@context/AccountContext';
import ImpersonatorBanner from '@components/ImpersonatorBanner/ImpersonatorBanner';
import jwtService from '@services/jwt.service';
import PermissionService from '@services/permission.service';
import { updateDocumentMetadata } from 'utils/updateDocumentMetadata';
import travelAssistFaviconPath from 'assets/images/travel_assist_favicon.ico';
import onsolveFaviconPath from 'assets/images/favicon.ico';
import MapService from '@services/map.service';
const { protocol } = window.location;
const externalDomainName = configurationService.ExternalDomainName;
const externalHost = `${protocol}//${externalDomainName}`;
const BuildInformation = () => {
    const BUILD_VERSION_KEY = 'build_version';
    const buildVersion = VERSION;
    const currentBuildVersion = LocalStorageUtility.getData(BUILD_VERSION_KEY);
    if (currentBuildVersion !== buildVersion) {
        LocalStorageUtility.setData(BUILD_VERSION_KEY, buildVersion);
        console.info({ currentBuildVersion, buildVersion });
    }
};
const ControlCenter = () => {
    const mfeHistory = useHistory();
    const { updateLanguageWithData, language } = useI18n();
    const { ControlCenter: controlCenter } = appNames;
    const newHistory = updateHistoryWithFilterId(mfeHistory);
    const [mapPreferences, setMapPreferences] = useState(null);
    const [loading, setLoading] = useState(true);
    const { canViewMapOff } = new PermissionService().permissions;
    useEffect(() => {
        const fetchMapPreferences = async () => {
            try {
                const preferences = await MapService.getAccountMapDisplay();
                if (canViewMapOff()) {
                    preferences.isVisible = false;
                }
                setMapPreferences(preferences);
            }
            catch (error) {
                // eslint-disable-line @typescript-eslint/no-explicit-any
                //Back-end will 404 for a brand new user with no preference
                const statusCode = error.response?.status || 500;
                if (statusCode === 404) {
                    const defaultPreferences = MapService.getDefaultMapViewDetails();
                    if (canViewMapOff()) {
                        defaultPreferences.isVisible = false;
                    }
                    setMapPreferences(defaultPreferences);
                }
                else {
                    console.error('Error fetching map preferences:', error);
                }
            }
            finally {
                setLoading(false);
            }
        };
        fetchMapPreferences();
    }, []);
    if (loading) {
        return <LoadingOverlay description="Please stand by." overlay/>;
    }
    return (<MicroFrontend history={newHistory} host={externalHost} setMFEi18n={updateLanguageWithData} mapPreferences={mapPreferences || null} name={controlCenter} locale={language?.isoCode}/>);
};
const AssetUI = (props) => {
    const mfeHistory = useHistory();
    const { updateLanguageWithData, language } = useI18n();
    const { AssetUI: assetui } = appNames;
    return (<MicroFrontend history={mfeHistory} host={externalHost} setMFEi18n={updateLanguageWithData} name={assetui} locale={language?.isoCode} {...props}/>);
};
const HistoricalReporting = (props) => {
    const mfeHistory = useHistory();
    const { updateLanguageWithData, language } = useI18n();
    const { HistoricalReporting: historicalreporting } = appNames;
    return (<MicroFrontend history={mfeHistory} host={externalHost} setMFEi18n={updateLanguageWithData} name={historicalreporting} locale={language?.isoCode} {...props}/>);
};
const CriticalComms = (props) => {
    const mfeHistory = useHistory();
    const { updateLanguageWithData, language } = useI18n();
    const { CriticalComms: criticalcomms } = appNames;
    const newHistory = updateHistoryWithFilterId(mfeHistory);
    return (<MicroFrontend history={newHistory} host={externalHost} setMFEi18n={updateLanguageWithData} name={criticalcomms} locale={language?.isoCode} {...props}/>);
};
const RiskIntelligence = () => {
    const mfeHistory = useHistory();
    const { push } = mfeHistory;
    const { RiskIntelligence: riskintelligence } = appNames;
    return (<MicroFrontend history={{
            ...mfeHistory,
            push: updatedPush(push, riskintelligence),
        }} host={externalHost} name={riskintelligence}/>);
};
const App = () => {
    const { loggedInUser: { firstName = '', lastName = '' } = {} } = useAccountContext() || {};
    const { canAccessAssetUI, canViewHistoricalReporting, canViewWTPCustomer } = new PermissionService().permissions;
    const [showBetaToastr, setShowBetaToastr] = useState(true);
    const betaToastrId = useRef('');
    window.mfeHistory = useHistory();
    useLayoutEffect(() => {
        applicationLoadHandler();
        renderBetaToastr();
        const isWTPCustomer = canViewWTPCustomer();
        const title = isWTPCustomer ? 'Travel Assist' : 'OnSolve';
        const faviconPath = isWTPCustomer ? travelAssistFaviconPath : onsolveFaviconPath;
        updateDocumentMetadata(title, faviconPath);
    }, []);
    useEffect(() => {
        // Helper function to set user details in Datadog if conditions are met
        const setUserDetailsInDatadog = () => {
            const orgUuid = jwtService.getOrganizationUuid();
            const userAssetId = canAccessAssetUI() ? jwtService.getUserAssetId() : jwtService.getUserUuid();
            window.DD_RUM.setUser({
                id: userAssetId,
                // email: userDetails?.email,
            });
            window.DD_RUM.setGlobalContextProperty('orgId', orgUuid);
            window.DD_RUM.setGlobalContextProperty('namespace', namespaceService.getNamespace());
            window.DD_RUM.setGlobalContextProperty('environment', namespaceService.getOriginWithoutNamespace());
        };
        // Check if DD_RUM and AuthService are ready, otherwise retry
        const intervalId = setInterval(() => {
            if (window.DD_RUM && AuthService.isAuthenticated) {
                setUserDetailsInDatadog();
                clearInterval(intervalId); // Stop retrying once conditions are met
            }
        }, 500); // Check every 500ms
        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [canAccessAssetUI]);
    const { riAccount, isLoadingRIAccount, riConfig } = useRIAccountContext(); //Note: async, `null` until complete, can have `data` or be `false`.
    const isImpersonatingUser = jwtService.isImpersonatingUser();
    const handleImpersonatorLogIn = async (userToImpersonateSub) => {
        const userToImpersonateIdIsEmpty = !userToImpersonateSub || userToImpersonateSub === '00000000-0000-0000-0000-000000000000'; //This is to make sure that the user to impersonate has signed in at least once
        try {
            if (userToImpersonateIdIsEmpty) {
                return;
            }
            const token = await AuthService.toggleImpersonation(userToImpersonateSub);
            if (token) {
                window.location.href = '/';
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    const handleCloseImpersonator = async () => {
        await AuthService.toggleImpersonation();
        window.location.reload();
    };
    const cookieService = new CookieService();
    const renderBetaToastr = () => {
        // What's New in Beta Toastr
        if (isBeta() && !isMobileAppView() && showBetaToastr) {
            const id = ToastrProvider.primary(() => <BetaToastrContent onClose={handleCloseBetaToastr}/>, {
                isAlwaysVisible: true,
                position: DomUtils.isRtl() ? 'top-left' : 'top-right',
                variant: 'primary',
            });
            betaToastrId.current = id;
        }
    };
    const handleCloseBetaToastr = () => {
        ToastrProvider.close(betaToastrId.current);
        setShowBetaToastr(false);
    };
    const authorizeCallback = async () => {
        const params = queryString.parse(window.location.search);
        const { ui_lang, origin, redirection } = params;
        i18nSetCookie(ui_lang);
        // The redirect from IdentityServer (/authorize endpoint) to the UI after successful authentication. The example URL format:
        // https://onsolve.com/callback?origin=https%3A%2F%2F{namespace}.onsolve.com%2F&code={code}&scope={scopes}&state={state}&session_state={session_state}
        if (origin) {
            delete params.origin;
            // Redirect to `origin/callback?{queryParams}&redirection={origin}`.
            window.location.href =
                namespaceService.getClientOrigin(origin) +
                    'callback?' +
                    queryString.stringify({
                        ...params,
                        redirection: origin,
                    });
        }
        // The redirect from OnSolve.UI from the `if` above. The example URL format:
        // https://{namespace}.onsolve.com/callback?code={code}&redirection=https%3A%2F%2F{namespace}.onsolve.com%2F&scope={scopes}&session_state={session_state}&state={state}
        else {
            try {
                await AuthService.updateState();
                // Redirect to `https://{namespace}.onsolve.com` if no errors.
                window.location.href = redirection ? redirection : '/';
            }
            catch (e) {
                const is401 = e.message === 'Unauthorized (401)';
                const mir3Logout = !is401;
                const clientErrorCode = is401
                    ? identityServerErrorCodes.noPermissionToLogin
                    : identityServerErrorCodes.signinLinkHasExpired;
                await AuthService.signOut(mir3Logout, clientErrorCode);
            }
        }
    };
    const applicationLoadHandler = async () => {
        if (window.location.pathname === '/callback') {
            await authorizeCallback();
        }
        else {
            //* Check if there is an existing originURL cookie.
            //* If so - check if there is a namespace in the URL.
            //* If there's no namespace - redirect the user to originURL.
            const namespace = namespaceService.getNamespace();
            const originUrl = cookieService.getCookieValue(cookieKeys.originURL);
            if (originUrl) {
                if (!namespace) {
                    window.location.href = originUrl;
                }
            }
            const { isAuthenticated } = AuthService;
            const isCurrentAuthDataToBeCleared = () => {
                //! OF-1848: For IdP initiated SSO a "clearAuth" parameter is added to the query string by OnSolve.IdentityServer.
                //! If the User was logged in previously, the local storage should be cleared instead of logging User out due to expired token.
                const queryParams = new URLSearchParams(window.location.search);
                return queryParams.get('clearAuth') != null;
            };
            if (!isAuthenticated || isCurrentAuthDataToBeCleared()) {
                if (isCurrentAuthDataToBeCleared()) {
                    AuthService.removeAuthData();
                }
                //! `client_error` parameter could be present in the query string if either `noPermissionToLogin`
                //! or `signinLinkHasExpired` occurred while signing in. See `authorizeCallback()` for more details.
                const clientErrorCode = new URLSearchParams(window.location.search).get('client_error');
                await AuthService.signIn(clientErrorCode);
            }
            isMobile.any() && document.body.classList.add('isMobile');
            // TODO: Temporary solution. Remove after applying new logo
            const { FeatureFlags } = configurationService;
            if (FeatureFlags.newBrandingLogo) {
                document.querySelectorAll('.temp-updatable').forEach((item) => {
                    item.setAttribute('href', '/updated' + item.getAttribute('href'));
                });
            }
        }
    };
    const isMobileAppView = () => navigator.userAgent.match(/MobileAppWebView/i);
    const rootClasses = isMobileAppView() ? 'onsolve-body onsolve-body--mobileapp' : 'onsolve-body';
    if (!AuthService.isAuthenticated) {
        return <LoadingOverlay description="Please stand by." overlay/>;
    }
    //* Defaulting to RI takes precedence over everything, if they have RI, default there.
    //* If they don't have RI, Control Center takes precedence over OnSolve.UI (criticalcomms)
    return (<>
            {!isDevelopment() && !isMobileAppView() && <SessionTimeoutModal />}
            <BrowserRouter>
                <div className="onsolve-page-wrapper" data-testid="left-nav">
                    {!isMobileAppView() && (<NavBar riskIntelligenceAccount={riAccount} riskIntelligenceConfigurations={riConfig}/>)}
                    <div className={rootClasses}>
                        {isImpersonatingUser && (<ImpersonatorBanner onClose={handleCloseImpersonator} message={`You are viewing this page as ${firstName} ${lastName}`}/>)}
                        <ToastrProviderComponent position={DomUtils.isRtl() ? 'top-left' : 'top-right'}/>
                        <div className={'d-flex flex-column h-100'}>
                            <Switch>
                                <Route path={MFERoutesEnum.riskIntelligence} render={() => {
            if (riAccount) {
                return <RiskIntelligence />;
            }
            else if (!isLoadingRIAccount) {
                return <Redirect to="/"/>;
            }
        }}/>
                                <Route path={assetUIRoutes} render={() => canAccessAssetUI() ? (<AssetUI handleImpersonatorLogIn={handleImpersonatorLogIn}/>) : (<CriticalComms handleImpersonatorLogIn={handleImpersonatorLogIn}/>)}/>
                                <Route path="/reports/eventreporting" render={() => canViewHistoricalReporting() ? (<HistoricalReporting handleImpersonatorLogIn={handleImpersonatorLogIn}/>) : (<CriticalComms handleImpersonatorLogIn={handleImpersonatorLogIn}/>)}/>
                                <Route path="/reports/dashboards" render={() => (<HistoricalReporting handleImpersonatorLogIn={handleImpersonatorLogIn}/>)}/>
                                <Route render={({ location }) => controlCenterRoutes.filter((route) => location.pathname.startsWith(route) || location.pathname === '/').length && !isMobileAppView() ? (<ControlCenter />) : (<CriticalComms handleImpersonatorLogIn={handleImpersonatorLogIn}/>)}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        </>);
};
BuildInformation();
export default App;
